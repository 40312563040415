import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const StyledFooter = Styled.footer`
  background-color: ${Theme.colorWhite};
  color: ${Theme.fontColorSecondary};
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0 1.5rem 0;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    padding: 0;
    font-size: 16px;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(var(--vh,1vh) * 22);

    &::before {
      content: '';
      box-shadow: inset 1px 1rem ${Theme.colorTertiary};
      display: block;
      height: 1px;
      margin: 0 auto auto auto;
      max-width: ${Theme.sizeContainer};
      width: 94%;

      @media screen and (min-width: 1080px) {
        max-width: ${Theme.sizeContainerXL};
      }
    }

    &::after {
      content: '';
      margin-left: auto;
      width: 0px;
      height: 0px;
      border-right: 0;
      border-left: calc(var(--vh,1vh) * 16) solid transparent;
      border-bottom: calc(var(--vh,1vh) * 17) solid ${Theme.colorPrimary};
    }
  }
`;

export const StyledFooterContainer = Styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
`;

export const SocialList = Styled.ul`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SocialLink = Styled.a`
  display: flex;
  align-items: center;
  jusitify-content: center;
`;

export const Copyright = Styled.span`
  display: block;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;
