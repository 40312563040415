import {
  useState, useEffect, useRef, useMemo,
} from 'react';
import ReactPlayer from 'react-player';
import IconNext from '../../atoms/Icon/IconNext';
import IconPlayVideo from '../../atoms/Icon/IconPlay';
import IconPrevious from '../../atoms/Icon/IconPrevious';
import useYear from '../../../hooks/useYear';
import useVideo from '../../../hooks/useVideo';
import {
  HomeNewsExpandClose, ModalVideo, ModalVideoContainer, ReactPlayerContainer, ReactPlayerControl,
  ReactPlayerContainerFirstVideo, ScrollableContainer, ScrollableContent, ScrollableItems,
  ScrollButtonsContainer, ScrollButton,
} from './StyledHome';

export default function HomeVideos() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { year } = useYear();
  const { data } = useVideo();
  const [showModal, setShowModal] = useState({ url: '', open: false });
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleWidth, setVisibleWidth] = useState(0);
  // const [playing, setPlaying] = useState('');
  // Importante: está comentada la funcionalidad que nos pidieron de que los videos
  // comenzaran cuando se les hiciera hover, porque finalmente no lo quieren (por ahora).

  const activeColor = '#D81E05';
  const disabledColor = '#717a80';

  const dataFiltered = useMemo(() => {
    if (data === undefined) return [];
    return data[0]?.info?.filter((item: any) => item.year === year && item.position !== 0)
      ?.sort((a: any, b: any) => a.position - b.position) || undefined;
  }, [data, year]);

  const firstVideo = useMemo(() => {
    if (data === undefined) return undefined;
    return data[0]?.info?.filter((item: any) => item.year === year && item.position === 0)?.[0]
     || undefined;
  }, [data, year]);

  const totalWidth = dataFiltered?.length ? 450 * dataFiltered.length : 0;
  const maxPosition = Math.max(0, totalWidth - visibleWidth);

  const scrollRight = () => {
    if (scrollPosition + 450 < maxPosition) {
      setScrollPosition(scrollPosition + 450);
    } else {
      setScrollPosition(maxPosition);
    }
  };

  const scrollLeft = () => {
    if (scrollPosition - 450 > 0) {
      setScrollPosition(scrollPosition - 450);
    } else {
      setScrollPosition(0);
    }
  };

  useEffect(() => {
    const updateVisibleWidth = () => {
      if (containerRef.current) {
        setVisibleWidth(containerRef.current.offsetWidth);
      }
    };

    updateVisibleWidth();
    window.addEventListener('resize', updateVisibleWidth);

    return () => window.removeEventListener('resize', updateVisibleWidth);
  }, []);

  // const handleMouseEnter = (id:any) => {
  //   setPlaying(id);
  // };

  // const handleMouseLeave = () => {
  //   setPlaying('');
  // };

  return (
    <>
      {firstVideo && (
        <ReactPlayerContainerFirstVideo
          key={firstVideo?.id}
          // onMouseEnter={() => handleMouseEnter(firstVideo.id)}
          // onMouseLeave={() => handleMouseLeave()}
        >
          <ReactPlayer
            url={firstVideo?.urlVimeo}
            // playing={playing === firstVideo?.id}
            muted
            style={{
              width: '640px', height: '340px',
            }}
          />
          <ReactPlayerControl
            onClick={() => {
              setShowModal({
                url: firstVideo?.urlVimeo,
                open: true,
              });
            }}
          >
            {firstVideo?.thumbnailLarge !== null && (
              <img src={firstVideo?.thumbnailLarge} alt={firstVideo?.title} />
            )}
            <IconPlayVideo />
            <span>
              {firstVideo?.title}
            </span>
          </ReactPlayerControl>
        </ReactPlayerContainerFirstVideo>
      )}
      {dataFiltered && dataFiltered.length > 0 && (
        <ScrollableContainer ref={containerRef}>
          <ScrollableContent>
            <ScrollableItems
              style={{ transform: `translateX(-${scrollPosition}px)`, width: `${750 * dataFiltered.length}px`, justifyContent: 'center' }}
            >
              {dataFiltered.map((item: any) => (
                <ReactPlayerContainer
                  key={item.id}
                  // onMouseEnter={() => handleMouseEnter(item.id)}
                  // onMouseLeave={() => handleMouseLeave()}
                >
                  <ReactPlayer
                    url={item.urlVimeo}
                    // playing={playing === item?.id}
                    muted
                    style={{
                      width: '390px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                  />
                  <ReactPlayerControl
                    onClick={() => {
                      setShowModal({
                        url: item.urlVimeo,
                        open: true,
                      });
                    }}
                  >
                    {item.thumbnailLarge !== null && (
                      <img src={item.thumbnailLarge} alt={item.title} />
                    )}
                    <IconPlayVideo />
                    <span>
                      {item.title}
                    </span>
                  </ReactPlayerControl>
                </ReactPlayerContainer>
              ))}
            </ScrollableItems>
          </ScrollableContent>
          <ScrollButtonsContainer>
            <ScrollButton
              type="button"
              onClick={scrollLeft}
              style={{ cursor: scrollPosition > 0 ? 'pointer' : 'not-allowed' }}
              disabled={scrollPosition === 0}
            >
              <IconPrevious color={scrollPosition > 0 ? activeColor : disabledColor} />
            </ScrollButton>
            <ScrollButton
              type="button"
              onClick={scrollRight}
              style={{ cursor: scrollPosition >= maxPosition ? 'not-allowed' : 'pointer' }}
              disabled={scrollPosition >= maxPosition}
            >
              <IconNext color={scrollPosition >= maxPosition ? disabledColor : activeColor} />
            </ScrollButton>
          </ScrollButtonsContainer>

          {showModal?.url !== undefined && (
            <ModalVideo show={showModal?.open}>
              <ModalVideoContainer>
                <HomeNewsExpandClose onClick={() => {
                  setShowModal({
                    url: '',
                    open: false,
                  });
                }}
                />
                <ReactPlayer
                  url={showModal?.url}
                  width="100%"
                  height="100%"
                  playing={showModal?.open}
                  config={{
                    file: {
                      attributes: {
                        preload: true,
                      },
                    },
                  }}
                />
              </ModalVideoContainer>
            </ModalVideo>
          )}
        </ScrollableContainer>
      )}
    </>
  );
}
