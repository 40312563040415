import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const CalendarItemShadow = Styled.div`
  box-shadow: 6px 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
`;

export const CalendarItem = Styled.div`
  color: ${Theme.colorWhite};
  position: relative;
  width: 100%;
  height: 320px;
  display: flex;
  align-items: flex-end;
  padding: 2rem;
  transition: transform 0.3s ease;

  div a:last-child {
    left: 90px;
  }

  a, p {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -1rem;
    transition: position 0.3s ease, bottom 0.3s ease;
  }

  img {
    border-radius: 20px;
    overflow: hidden;
  }

  &:after {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 20px;
  }

  &:hover {
    transform: scale(1.2);
    z-index: 4;
    &:after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%);
    }

    a {
      opacity: 1;
      visibility: visible;
      position: relative;
      bottom: -20px;
      z-index: 50;
      animation: animacion 2.5s ease forwards; /* Activa la animación */
    }

    p {
      opacity: 1;
      visibility: visible;
      position: relative;
      bottom: 0;
    }

    h2 {
      transform: translateY(-0.5rem);
    }
  }
  @keyframes animacion {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(1.5); /* Giro a medio camino y más grande */
    }
    100% {
      transform: rotate(360deg) scale(1); /* Tamaño original y un poco más arriba */
    }
  }
`;

export const CalendarImage = Styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%,-50%);
  object-fit: cover;
`;

export const CalendarContent = Styled.div`
  position: relative;
  z-index: 3;
  max-height: 120px;
`;

export const CalendarTitle = Styled.h2`
  font-family: 'Fixture Condensed Medium',sans-serif;
  font-size: 46px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 0.62rem;
  transition: all 0.3s ease;
`;

export const CalendarParagraph = Styled.p`
  font-size: 16px;
  line-height: 1.25;
  margin: 0;
  transition: all 0.3s ease;
`;
