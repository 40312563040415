import { useQueryClient } from 'react-query';
import { Calendar, DataCalendar } from '../../../interfaces/Calendar.d';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import Grid from '../../atoms/Grid/Grid';
import LinkTo from '../../atoms/Link/LinkTo';
import {
  CalendarContent,
  CalendarImage,
  CalendarItem,
  CalendarItemShadow,
  CalendarParagraph,
  CalendarTitle,
} from '../../molecules/Calendar/StyledCalendar';
import getCalendarData from './dataSummaryCalendar';
import useYear from '../../../hooks/useYear';
import isMediator from '../../../hooks/useUserType';

export default function SummaryCalendar() {
  const { year } = useYear();
  const { data } = getCalendarData();

  const dataFiltered = data
    .filter((item: DataCalendar) => item.year === year)[0]
    .info;

  const queryClient = useQueryClient();

  let dataCategory: any = {};
  dataCategory = queryClient.getQueryState(['UserCategory']);

  return (
    <Container>
      <Grid
        columnsLg={4}
        spaceBetween={1}
        rowHeight={['320px', '320px']}
        maxWidth="96%"
        width="1300px"
        margin="4rem auto"
        regular
      >
        {dataFiltered.map((calendar: Calendar) => (
          ((calendar.title === 'Ranking'
          && dataCategory?.data
          && !isMediator(dataCategory?.data?.userCategory?.id))
          || calendar.title !== 'Ranking')
          && (
            <CalendarItemShadow key={calendar.id}>
              <CalendarItem key={calendar.id}>
                <CalendarImage src={calendar.image} alt={calendar.title} />
                <CalendarContent>
                  <CalendarTitle>{calendar.title}</CalendarTitle>
                  <CalendarParagraph>
                    {calendar.paragraph}
                  </CalendarParagraph>
                  <LinkTo
                    to={{ pathname: `/calendar/${calendar.link.url}` }}
                    background={Theme.colorPrimary}
                    color={Theme.colorWhite}
                    padding="0 17px"
                    underline="none"
                    border="50%"
                    size="50px"
                  >
                    <div style={{ marginTop: '-2px', marginLeft: '-1px' }}>+</div>
                  </LinkTo>
                </CalendarContent>
              </CalendarItem>
            </CalendarItemShadow>
          )
        ))}
      </Grid>
    </Container>
  );
}
