import { ITerritoryStatistic } from '../interfaces/ITerritoryStatistic';
import Theme from '../styles/Theme';
import IconCup from '../components/atoms/Icon/IconCup';

export default function useRanking(terr: ITerritoryStatistic[], year: number) {
  const iconParams = {
    color: Theme.colorRanking,
    width: '38px',
    height: '38px',
  };

  const rankings = [
    {
      year: 2022,
      info: [
        {
          id: 'GLOBAL',
          title: 'Ranking',
          subtitle: 'GLOBAL',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+2022.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'ASESOR_MANAGERS',
          title: 'Ranking',
          subtitle: 'Asesores/Managers R.Específica',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022%2BEspecifica_Asesores_Managers.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+2022_ASESORES_MANAGERS.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'CORREDORES',
          title: 'Ranking',
          subtitle: 'CORREDORES',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022_Corredores.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+2022+CORREDORES.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'ASESOR_COMERCIAL',
          title: 'Ranking',
          subtitle: 'ASESORES COMERCIALES',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022_Asesores%2BComerciales...pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+2022_AC.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'ECVD',
          title: 'Ranking',
          subtitle: 'ECVD VIDA Y SALUD',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022_Especifica_ECVDS.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+ECVD+2022.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'EJECUTIVO_CUENTAS',
          title: 'Ranking',
          subtitle: 'EJECUTIVO CUENTAS CORREDORES',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022_Ejecutivo%2Bde%2BCuentas%2BCorredores.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+2022_EJCUENTAS.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'ASESOR_CLIENTES',
          title: 'Ranking',
          subtitle: 'ASESORES CLIENTES',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022_Asesores%2BClientes.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+2022_ACLIENTES.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'ESPECIFICA',
          title: 'Ranking',
          subtitle: 'RED ESPECÍFICA',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022_Especifica.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+2022_ESPECIFICA.xlsm',
              visible: true,
            },
          ],
        },
        {
          id: 'AUTOMOCION',
          title: 'Ranking',
          subtitle: 'AUTOMOCIÓN',
          date: 'Diciembre 2022',
          checks: [
            {
              checkName: 'Procedimiento',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Procedimientos/PROCEDIMIENTO%2BCALCULO%2BRANKING%2B2022_Automocion%2C%2BPromotores%2B%2By%2BEC.pdf',
              visible: true,
            },
            {
              checkName: 'Ranking',
              checkLink: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/RANKING+GLOBAL+AUTOMOCION+2022.xlsm',
              visible: true,
            },
          ],
        },
      ],
    },
    {
      year: 2023,
      info: terr ? terr.map((terstat: ITerritoryStatistic, index: number) => ({
        position: index,
        value: terstat.monthValue,
        name: `${terstat.territory.code} - ${terstat.territory.name}`,
        icon: index < 3 ? IconCup(iconParams) : '',
      })) : [],
    },
    {
      year: 2024,
      info: terr ? terr.map((terstat: ITerritoryStatistic, index: number) => ({
        position: index,
        value: terstat.monthValue,
        name: `${terstat.territory.code} - ${terstat.territory.name}`,
        icon: index < 3 ? `https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/${index + 1}.gif` : '',
      })) : [],
    },
    {
      year: 2025,
      info: terr ? terr.map((terstat: ITerritoryStatistic, index: number) => ({
        position: index,
        value: terstat.monthValue,
        name: `${terstat.territory.code} - ${terstat.territory.name}`,
        icon: index < 3 ? `https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Ranking/Ranking/${index + 1}.gif` : '',
      })) : [],
    },
  ];

  const rankingResult = rankings
    ?.filter((item: any) => item.year === year)
    ?.map((item: any) => item.info).flat();

  return { rankingResult };
}
