import Styled, { css } from 'styled-components';
import play from '../../../assets/images/play.png';
import Theme from '../../../styles/Theme';

export const StyledBannerContainer = Styled.div`
  @media screen and (min-width: 768px) {
    position: relative;
    background-color: #F3F3F3;
    margin-top: 30px;
  }
`;

export const StyledBannerArticle = Styled.div<{cardsCount?: number}>`
  z-index: 1;
  width: fit-content;

  h2 {
    color: ${Theme.colorBlack};
    font-weight: 600;
    margin: 0 0 1rem;
    font-size: 20px !important;
    line-height: 1.12;
    width: fit-content;
    text-transform: uppercase;
  }

  p {
    color: ${Theme.fontColor};
  }

  img {
    position: absolute;
    bottom: -2.8rem;
    left: -0.5rem;
  }

  div {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    text-align: center;

    h2 {
      width: fit-content;
      font-size: 60px;
      margin-top: 24px;
    }

    p {
      font-size: 14px;
    }

    @media screen and (min-width: 1320px) {
      img {
        display: ${(props) => (props.cardsCount && props.cardsCount === 4 ? 'none' : 'block')};
      }
    }

    @media screen and (min-width: 1670px) {
      img {
        display: ${(props) => (props.cardsCount && props.cardsCount >= 4 ? 'none' : 'block')};
      }
    }
  }
`;

export const StyledBannerCarouselWrapper = Styled.div`
  border: 2px solid yellow;
  padding: 0 10% 2rem 30%;

  .carousel__slider-tray--horizontal {
    display: flex;
    gap: 1rem;

    > div {
      flex-shrink: 0;
      border: 1px solid green;
      padding-bottom: 0 !important;
      height: 230px !important;
      width: 250px !important;
      float: none !important;

      > div {
        > li {
          padding: 0 !important;
        }
      }
    }
  }
`;

export const StyledBannerCarousel = Styled.div`
  margin-left: 0;
  margin-right: -1rem;
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: 768px) {
    top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledBannerList = Styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  overflow: auto;
  position: relative;
  z-index: 2;

  .container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    justify-content: center;
  }

  @media screen and (min-width: 768px) {
    /* padding-left: 400px;
    padding-right: 160px; */
    /* padding: 0 10% 2rem 30%; */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledBannerItem = Styled.li`
  flex-shrink: 0;
  padding: 3rem 0.5rem;
  transition: all 0.3s ease;
  @media screen and (min-width: 768px) {
    padding: 4rem 0.5rem;
  }
  &:hover {
    transform: translateY(-30px); /* Mover hacia arriba 30px */
  }
`;

export const StyledBannerCard = Styled.div<{color?: string}>`
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 320px;
  width: 250px;

  &:hover {
    border-radius: 20px;
    box-shadow: 5px 5px 0 ${(props) => (props.color)};
    transition: all 0.3s ease;
    .title {
      margin: 0 0 0.7rem;
    }

    .links {
      opacity: 1;
      visibility: visible;
      position: relative;
      bottom: 0;
      width: 100%;
      height: fit-content;
    }

    .overlay {
      opacity: 1;
    }

    .footer-banner {
      opacity: 1;
      background: white;
      transition: all 0.3s ease;
      border: none;
    }
  }
`;

export const StyledBannerImage = Styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%,-50%);
  object-fit: cover;
  z-index: 1;
`;

export const StyledBannerImageWrapper = Styled.div``;

export const StyledBannerOverlay = Styled.div<{color?: string}>`
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.17%, rgba(0, 0, 0, 0.82) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: 2;
  border: 5px solid ${(props) => (props.color)};
  border-bottom: none;
  border-radius: 20px;

  @media screen and (min-width: 768px) {
    opacity: 1;
  }
`;

export const StyledFooterBanner = Styled.div<{color?: string}>`
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 18px 14px 12px 24px;
  border-top: 5px solid ${(props) => (props.color)};
  z-index: 2;
`;

export const StyledBannerLink = Styled.a<{color?: string}>`
  background-color: ${(props) => (props.color)};
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 900;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 0.7rem 0;
  display: inline-block;
  font-size: 14px;
  width: 100%;
  text-align: center;
  margin-top: 0.7rem;
`;

export const StyledBannerLinkMask = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledBannerTitle = Styled.h3<{color?: string}>`
  font-family: 'Fixture Condensed Medium', sans-serif;
  color: ${(props) => (props.color)};
  font-weight: 500;
  font-size: 30px !important;
  line-height: 1;
  text-transform: uppercase;
  padding: 0;
  transition: all 0.3s ease;
  max-width: 89%;
  margin: 0 0 2rem;
  z-index: 2;

  @media screen and (min-width: 768px) {
    font-size: 46px;
    margin: 0;
  }
`;

export const StyledBannerLinks = Styled.div`
  transition: all 0.3s ease;
  margin-top: 10px;
  height: 36px;
`;

export const StyledBannerWrapLinks = Styled.div`
  z-index: 2;

  @media screen and (min-width: 768px) {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -1rem;
    transition: position 0.3s ease, bottom 0.3s ease;
  }
`;

export const StyledHomeWinners = Styled.div`
  background: ${Theme.colorSecondary4};
  overflow: hidden;
  width: 100%;
  padding: 2rem 0;

  @media screen and (min-width: 768px) {
    padding: 2rem 0 5rem 0;
  }

  .carousel__dot-group {
    text-align: center;
  }

  .carousel__dot {
    height: 12px;
    border-radius: 6px;
    border: none;
    background-color: ${Theme.colorSecondary3};
    margin: 0 18px;
    vertical-align: middle;
  }

  .carousel__dot--selected {
    background-color: ${Theme.colorPrimary};
  }

  .carousel__back-button {
    font-family: inherit;
    font-weight: 900 !important;
    font-size: 18px;
    color: #D81E05;
    background: none;
    border: none;
    padding: 0;
    margin: 0 18px;
  }

  .carousel__next-button {
    font-family: inherit;
    font-weight: 900 !important;
    font-size: 18px;
    color: #D81E05;
    background: none;
    border: none;
    padding: 0;
    margin: 0 18px;
  }
`;

export const StyledHomeWinnersContainer = Styled.div`
  max-width: 1556px;
  width: 94%;
  margin: 0 auto;
  display: grid;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 220px 1fr 220px;
  }
`;

export const StyledHomeWinnersHeader = Styled.div`
  color: ${Theme.colorSecondary};
  text-align: center;
  margin: 3rem auto 4rem auto;

  span {
    font-size: 16px;
    margin: 0 auto;
  }

  p {
    font-size: 18px;
    margin: 1rem 0 1.5rem;
  }

  b {
    margin: 0 0.5rem;
  }

  small {
    font-size: 12px;
  }

  @media screen and (min-width: 768px) {
    margin: 8rem auto 3rem auto;

    span {
      font-size: 24px;
    }

    p {
      font-size: 28px;
      margin: 0 0 1.5rem;
    }

    small {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 1024px) {
    grid-column: 2;
  }
`;

export const StyledHomeWinnersGroup = Styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHomeHinnersCard = Styled.li`
  background: ${Theme.colorWhite};
  border-radius: 20px;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
  color: ${Theme.colorSecondary};
  flex-shrink: 0;
  text-align: center;
  padding: 1rem 0.5rem;
  width: 120px;
  height: 220px;

  &:nth-child(1) {
    width: 140px;
    height: 240px;
    z-index: 2;
    svg {
      width: 48px;
    }

    .number {
      top: 0.25rem;
    }
  }

  &:nth-child(2) {
    order: -1;
    margin-right: -0.5rem;
  }

  &:nth-child(3) {
    margin-left: -0.5rem;
  }

  .cup {
    position: relative;
    margin: 0.5rem auto;
  }

  .number {
    color: ${Theme.colorWhite};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-weight: bold;
  }

  svg {
    width: 30px;
  }

  h3 {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2;
    margin: 0 auto 0.5rem auto;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 11px;

    li {
      display: inline-block;

      &::after {
        content: '/';
        display: inline-block;
        margin: 0 0.12rem;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    width: 244px;
    height: 234px;
    padding: 1rem;

    h3 {
      font-size: 15px;
    }

    ul {
      font-size: 13px;
    }

    svg {
      width: 44px;
    }

    .number {
      top: 0.25rem;
    }

    &:nth-child(1) {
      width: 277px;
      height: 290px;
      padding: 2rem 1.72rem;

      svg {
        width: 55px;
      }

      .number {
        top: 0.5rem;
      }

      .cup {
        margin: 1rem auto 1.5rem auto;
      }
    }
  }
`;

export const StyledSlideItem = Styled.div`
  flex-shrink: 0;
  text-align: center;

  img {
    display: inline-block;
    margin: 1rem auto;
  }
`;

export const StyledSlideList = Styled.div`
  min-height: 350px;

  .carousel__slide {
    height: 240px;
    padding-bottom: 340px !important;
  }

  .carousel__slider--horizontal {
    width: 100%;
    overflow: hidden;
  }

  .carousel__slider {
    margin-right: auto;
    margin-left: auto;
  }
  
  @media screen and (min-width: 768px) {
    .carousel__container {
      max-width: 622px;
      margin: auto;
    }

    .carousel__slide{
      height: 350px;
      padding-bottom: 420px !important;
      transition: all 0.3s ease;
    }

    .carousel__slide--hidden {
      z-index: -1;
      opacity: 0.5;
      transform: scale(0.85);
    }

    .carousel__inner-slide {
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      left: 10px;
      top: 10px;
    }
  }
`;

export const SliderButtonWrapper = Styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StyledVideoGroup = Styled.div<{items?: number}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% - 2rem);
  margin: 0 auto 2rem auto;
  overflow: hidden;

  > div {
    border-radius: 1rem;
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    padding-bottom: 60%;
    height: 0 !important;
    width: 100% !important;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .react-player__preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover {
      .react-player__play-icon {
        transform: scale(1.2);
      }
    }
  }

  .react-player__shadow {
    cursor: pointer;
  }

  .react-player__shadow {
    background: none !important;
  }

  .react-player__play-icon {
    border: 0 !important;
    background-image: url(${play});
    background-position: center center;
    background-size: cover;
    width: 56px;
    height: 56px;
    margin: 0 auto !important;
    transition: all 0.3s ease;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    display: flex;
    gap: 1rem;
    margin: 0;
    max-width: calc(1645px - 1rem);
    align-self: center;

    > div {
      padding-bottom: ${(props) => (props.items === 2 ? '28%' : '19%')}
    }
  }

  > div {
    border-radius: 20px;
    overflow: hidden;
  }
`;

export const ReactPlayerContainer = Styled.div`
    width: 390px;
    height: 200px;
    border-radius: 20px;
    margin-right: 30px;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    div {
      border-radius: 30px !important;
      height: 220px !important;
      overflow: hidden !important;
    }
`;

export const ReactPlayerContainerFirstVideo = Styled.div`
    width: 600px;
    height: 340px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 60px auto 0 auto;
    div {
      border-radius: 30px !important;
      height: 340px !important;
      overflow: hidden !important;
    }
`;

export const ReactPlayerControl = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: pointer;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%,-50%);
    object-fit: cover;
    z-index: 1;
  }
    
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 60px;
    height: 60px;
    opacity: 0.72;
    fill: white;
    transition: all 0.3s ease;
    z-index: 2;
  }

  span {
    color: white;
    position: absolute;
    bottom: 10%;
    left: 10%;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.1;
    display: block;
    z-index: 2;
  }
`;

export const ScrollableContainer = Styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
`;

export const ScrollableContent = Styled.div`
  width: 100%;
  height: 220px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const ScrollableItems = Styled.div`
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  margin-right: auto;
  margin-left: auto;
`;

export const ScrollButtonsContainer = Styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

export const ScrollButton = Styled.button`
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export const StyledThumbnail = Styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: cover;
  }
`;

export const StyledTitleOverlay = Styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
`;

export const StyledPlayButton = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 10px;
  color: white;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const ModalVideo = Styled.div<{show: boolean}>`
  background: rgba(0,0,0,0.82);
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: ${(props) => (!props.show ? '0' : '1')};
  visibility: ${(props) => (!props.show ? 'hidden' : 'visible')};
  z-index: 4;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ModalVideoContainer = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 94vw !important;
  height: 0;
  transform: translate(-50%, -50%);
  padding-top: calc(27vw + 27vh);

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 94vw !important;
    height: 0 !important;
    transform: translate(-50%, -50%);
    padding-top: calc(27vw + 27vh);
    overflow: hidden;
    border-radius: 20px;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  @media screen and (min-width: 768px) {
    width: calc(48vw + 48vh) !important;

    > div {
      width: calc(48vw + 48vh) !important;
    }

    button {
      top: -2rem;
    }

  }
`;

export const HomeNewsWidget = Styled.div`
  background: ${Theme.colorPrimary};
  border-radius: 0px 30px 30px 0px;
  cursor: pointer;
  position: fixed;
  overflow: hidden;
  top: 14rem;
  left: 0;
  z-index: 2;
`;

export const HomeNewsCta = Styled.div<{isOpen: boolean}>`
  position: relative;
  height: 60px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: ${(props) => (!props.isOpen ? '0' : '1')};
  visibility: ${(props) => (!props.isOpen ? 'hidden' : 'visible')};
  position: ${(props) => (!props.isOpen ? 'absolute' : 'relative')};
  left: ${(props) => (!props.isOpen ? '-2rem' : '0')};
`;

export const HomeNewsCtaContainer = Styled.div`
  position: relative;
  margin: 0.25rem auto 0 auto;
`;

export const HomeNewsCtaIndicator = Styled.span`
  background: #D81E05;
  box-shadow: 0 0 3px rgba(40, 0, 0, 0.6);
  border-radius: 2rem;
  color: ${Theme.colorWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -0.72rem;
  left: -0.72rem;
`;

export const HomeNewsExpand = Styled.div<{isOpen: boolean}>`
  border-radius: 0px 20px 20px 0px;
  color: ${Theme.colorWhite};
  opacity: 0;
  visibility: hidden;
  position: absolute;
  max-width: 290px;
  padding: 1rem 2rem 2rem 2rem;
  opacity: ${(props) => (!props.isOpen ? '0' : '1')};
  visibility: ${(props) => (!props.isOpen ? 'hidden' : 'visible')};
  position: ${(props) => (!props.isOpen ? 'absolute' : 'relative')};

  a {
    color: ${Theme.colorWhite};
  }


  @media screen and (min-width: 768px) {
    max-width: 390px;
  }
`;

export const HomeNewsExpandTitle = Styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 0 1rem;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
`;

export const HomeNewsExpandClose = Styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-left: auto;
  width: 30px;
  height: 20px;
  position: relative;

  &:after,
  &:before {
    content: '';
    display: block;
    background: ${Theme.colorWhite};
    border-radius: 1rem;
    position: aboslute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 3px;
    margin: 0 auto;
  }

  &:before {
    transform: rotate(46deg) translate(1px, 1px);
  }

  &:after {
    transform: rotate(-46deg) translate(1px, -1px);
  }
`;

export const HomeNewsExpandContainer = Styled.div`
  overflow: hidden;
`;

export const HomeNewsExpandGroup = Styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 260px;
  padding-right: 1rem;

  scrollbar-color: #F5F6F7 rgba(232, 235, 237, 0.5);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(232, 235, 237, 0.5);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #F5F6F7;
    border-radius: 6px;
  }
`;

export const HomeNewsExpandItem = Styled.li`
  border-bottom: 1px solid ${Theme.colorWhite};

  a {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }

  span {
    display: block;
    margin: 0.82rem 0;
    font-size: 14px;
    font-weight: 500;
  }

  &:last-child {
    border-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

export const StyledHomeWinnersLink = Styled.a`
  background: ${Theme.colorPrimary};
  border-radius: 10px;
  box-shadow: 0px 4px 19px rgb(0, 0, 0, 0.10);
  color: ${Theme.colorWhite};
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  margin: -1rem auto 1rem auto;
  width: 200px;
  height: 210px;

  @media screen and (min-width: 1024px) {
    margin: 6rem auto 0 auto;
    grid-column: 3;
  }
`;

export const WinnerListAlert = Styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto 30px auto;
  width: 94%;
  max-width: 1556px;
  z-index: 2;
  text-align: center;
  a {
    color: ${Theme.colorWhite};
  }
`;

export const WinnerItemAlert = Styled.li`
  margin: 1rem auto;
  border-radius: 20px;
`;

export const WinnerTitle = Styled.span`
  font-family: 'DM Sans',sans-serif;
  font-size: 16px;
  display: block;
  position: absolute;
  margin: 1rem 1.5rem 3rem;
`;

export const WinnerToggle = Styled.button`
  font-family: 'DM Sans',sans-serif;
  font-size: 16px;
  border: none;
  background-color: transparent;
  margin: 2rem 1.5rem 0 auto;
  cursor: pointer;
  text-decoration: underline;
`;

export const WinnerToggleContainer = Styled.span`
  position: absolute;
  bottom: 20px;
  right: 0;
`;

const StyledWinnerItem = css`
  background-color: ${Theme.colorPrimary};
  border-radius: 10px;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
  color: ${Theme.colorWhite};
  font-size: 1.12rem;
  padding: 2.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.72rem;
  text-decoration: none;
`;

export const WinnerLinkAlert = Styled.a`
  ${StyledWinnerItem};
  font-family: 'Fixture Condensed Medium', sans-serif;
  margin: 0 auto;
  z-index: 2;
  font-size: 42px;
  width: 75%;
  text-transform: uppercase;
  text-align: left;
  margin-left: 0;
`;

export const WinnerTextAlert = Styled.div<{index?: number}>`
  padding: 1.5rem 1.5rem 0;
  display: block;
  align-items: center;
  justify-content: center;
  gap: 0.72rem;
  text-decoration: none;
  color: ${Theme.colorPrimary};
  top: ${(props) => (props.index === 0 ? '20px' : 'auto')};

  font-family: 'DM Sans',sans-serif;
  font-weight: 700;
  line-height: 1.1;
  margin: 0 auto;
  z-index: 2;
  font-size: 24px;
  text-transform: uppercase;
  text-align: left;
  margin-left: 20px;
  width: 100%;
`;

export const WinnerText = Styled.div<{width?: string}>`
  ${StyledWinnerItem};
  flex-direction: column;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: -2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.width || '100%'};
`;

export const NotificationSeparator = Styled.hr`
  background-color: ${Theme.colorPrimary};
  color: ${Theme.colorPrimary};
  max-width: 500px;
  margin: 2rem 0 0;
  border: none;
  height: 1px;
`;
