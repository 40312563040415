interface IUser {
  id?: number,
  email?: string,
  firstName?: string,
  lastName?: string,
  endDate?: string,
}

export default function useImpersonate() {
  const impersonating = () => {
    if (localStorage.getItem('impersonateUser')) {
      return true;
    }

    return false;
  };

  const impersonatedUser = localStorage.getItem('impersonateUser')?.length ? JSON.parse(localStorage.getItem('impersonateUser') || '') as IUser : null;

  return {
    impersonating,
    impersonatedUser,
  };
}
