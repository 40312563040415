import Typography from '../Typography/Typography';
import {
  ICard,
  ICardBody,
  ICardContent,
  ICardFooter,
  ICardContentCover,
  ICardHeaderGraphics,
  IHeaderAmount,
  ICardHeader,
} from './ICard';
import {
  StyledCard,
  StyledCardHeader,
  StyledCardBody,
  StyledCardContent,
  StyledCardContentCover,
  StyledCardFooter,
  StyledHeaderAmount,
  StyledHeaderGraphic,
} from './StyledCard';

export function Card({
  children,
  background,
  display,
  alignItems,
  flexWrap,
  flexDirection,
  justifyContent,
  margin,
  ratio,
  padding,
  gap,
  theme,
  overlay,
  radius,
  minWidth,
  minHeight,
  className,
  flex,
  flexGrow,
  overflow,
  width,
  height,
  childs,
  ...rest
} : ICard) {
  return (
    <StyledCard
      theme={theme}
      background={background}
      radius={radius}
      display={display}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      alignItems={alignItems}
      flexWrap={flexWrap}
      gap={gap}
      ratio={ratio}
      padding={padding}
      overlay={overlay}
      margin={margin}
      className={className}
      minWidth={minWidth}
      minHeight={minHeight}
      flex={flex}
      flexGrow={flexGrow}
      overflow={overflow}
      width={width}
      height={height}
      childs={childs}
      {...rest}
    >
      {children}
    </StyledCard>
  );
}

export function CardHeader({
  children,
  display,
  flexDirection,
  justifyContent,
  alignItems,
  padding,
  ...rest
} : ICardHeader) {
  return (
    <StyledCardHeader
      display={display}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      padding={padding}
      {...rest}
    >
      {children}
    </StyledCardHeader>
  );
}

export function CardBody({
  children,
  padding,
  display,
  ...rest
}: ICardBody) {
  return (
    <StyledCardBody
      padding={padding}
      display={display}
      {...rest}
    >
      {children}
    </StyledCardBody>
  );
}

export function CardContent({
  children,
  padding,
  display,
  justifyContent,
  flexDirection,
  ...rest
} : ICardContent) {
  return (
    <StyledCardContent
      padding={padding}
      display={display}
      justifyContent={justifyContent}
      flexDirection={flexDirection}
      {...rest}
    >
      {children}
    </StyledCardContent>
  );
}

export function CardFooter({
  children,
  background,
  radius,
  padding,
  display,
  flex,
  justifyContent,
  flexDirection,
  alignItems,
  ...rest
}: ICardFooter) {
  return (
    <StyledCardFooter
      background={background}
      padding={padding}
      display={display}
      flex={flex}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      radius={radius}
      {...rest}
    >
      {children}
    </StyledCardFooter>
  );
}

export function CardHeaderGraphics({
  children,
}: ICardHeaderGraphics) {
  return (
    <StyledHeaderGraphic>
      {children}
    </StyledHeaderGraphic>
  );
}

export function CardContentCover({
  children,
}: ICardContentCover) {
  return (
    <StyledCardContentCover>
      {children}
    </StyledCardContentCover>
  );
}

export function HeaderAmount({
  amount,
  gain,
  theme,
  year,
  type,
}: IHeaderAmount) {
  return (
    <StyledHeaderAmount>
      <Typography
        size="16px"
        type="span"
      >
        {amount ? 'Importe*:' : 'Gana:'}
        <Typography
          type="span"
          margin="0"
          display="flex"
        >
          {type !== 'convention' && year === 2022 && (<small>Provisional</small>)}
        </Typography>
      </Typography>
      <Typography
        color={theme}
        size="24px"
        margin="0"
        weight="500"
      >
        { amount ? `${(amount)} €` : gain}
      </Typography>
    </StyledHeaderAmount>
  );
}
