import { Link } from 'react-router-dom';
import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';

export const StyledCard = Styled.div<{display?: string, background?:string, padding?: string, ratio?: number, theme?: string, overlay?: boolean, radius?: string, justifyContent?:string, flexDirection?: string, alignItems?: string; gap?: string, margin?: string, className?: string, minWidth?: string, minHeight?: string, flex?: string, flexWrap?: string, flexGrow?: string, overflow?: string, width?: string, height?: string, childs?: number}>`
  background-color: ${(props) => props.background ?? Theme.colorSecondary2};
  border-radius: ${(props) => (props.radius ? props.radius : 0)};
  position: relative;
  padding: ${(props) => props.padding};
  display: ${(props) => props.display ?? 'block'};
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
  flex-wrap: ${(props) => props.flexWrap};
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};
  flex: ${(props) => props.flex};
  flex-grow: ${(props) => props.flexGrow};
  overflow: ${(props) => props.overflow};
  width: ${(props) => props.width};
  gap: ${(props) => ((props.display === 'flex' && props.childs !== undefined) ? '1rem' : '')};
  max-height: ${(props) => props.height};

  .auto-width {
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
  }

  > div {
    width: ${(props) => ((props.display === 'flex' && props.childs !== undefined) ? `${(100 / props.childs)}%` : '')};
  }

  div > .auto-width > div {
    overflow-x: auto;
  }

  &::before {
    border-radius: 20px;
    content: '';
    background-color: ${Theme.colorSecondary};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: 2;
    display: ${(props) => (!props.overlay ? 'none' : 'block')};
  }

  figure {
    z-index: 1;
  }

  .title {
    color: ${(props) => props.theme};
    margin: 0;
    font-weight: 500;
    font-size: 22px;
  }

  .amount {
    background-color: ${Theme.colorWhite};
    border-radius: ${Theme.radius};
    color: ${Theme.fontColorSecondary};
    padding: 1rem;
    display: flex;
    align-items: top;
    gap: 1rem;

    small {
      display: block;
    }

    b {
      font-size: 20px;
      color: ${(props) => props.theme};
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1rem;
  }

  .graphic--progressbar {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0 0 1rem;
  }

  .graphic__title {
    font-size: 16px;
    color: ${Theme.fontColorSecondary};
    font-weight: 400;
  }

  .graphic--progressbar .graphic__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }

  .graphic--progressbar .graphic__total {
    border-radius: ${Theme.radius};
    position: relative;
    overflow: hidden;
    height: 15px;
    width: 100%;

    &::before{
      content: '';
      background-color: ${Theme.colorSecondary3};
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }

  .graphic--progressbar .graphic__score {
    font-weight: 600;
    font-size: 18px;
    width: 70px;
    flex-shrink: 0;
    text-align: right;
  }

  .graphic--progressbar .graphic__progress {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 2rem 2rem 0;
  }

  .group__graphic--half-circle .graphic__total{
    position: relative;
    width: 100px;
    height: 49px;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
    border-bottom: 0;
    background: #d9d9d9;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      display: block;
      background: ${Theme.colorSecondary2};
      z-index: 2;
      width: calc(100% - 32px);
      height: calc(200% - 32px);
      border-radius: 50%;
      top: 16px;
      left: 16px;
    }
  }

  .group__graphic--half-circle .graphic__progress {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    border-radius: 50%;
    transition: transform .5s ease-in-out;
    z-index: 1;
    transform: rotate(270deg);
  }

  .group__graphic--half-circle {
    display: flex;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0.5rem;
  }

  .group__graphic--half-circle .graphic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 1rem;
    text-align: center;
    gap: 0.5rem;
  }

  .group__graphic--half-circle .graphic__score {
    font-weight: 600;
    font-size: 16px;
    text-decoration: underline;
  }

  .graphic--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link {
      margin: 0;
    }
  }

  time {
    color: ${Theme.fontColorSecondary}
  }

  .link--graphic {
    color: ${Theme.colorPrimary};
  }

  &.card__item {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease;

    .card__figure {
      border-radius: 8px 8px 0 0;
    }

    .link__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
    }

    &:hover {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.12);
      transform: translateY(-3px);
    }
  }

  @media screen and (min-width: 768px) {

    .card__title {
      font-family: 'Fixture Condensed Medium', sans-serif;
      font-size: 58px;
      margin: 0;
      width: 100%;
    }

    .card__paragraph {
      font-size: 18px;
    }

    .btn {
      margin: 0 0 1rem;
    }

    .group__graphic,
    .graphic--footer {
      padding: 0 2rem;
    }
  }

  .image__card {
    border-radius: 20px;
  }
`;

const StyledLoading = css`
  background: #d0dadf;
  background: linear-gradient(110deg, #d0dadf 8%, #ececec 18%, #d0dadf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin: 0 auto;
  height: 28px;
  width: 100%;

  @keyframes shine {
    to {
      background-position-x: -250%;
    }
  }
`;

export const SkeletonCardWrapper = Styled.div<{cols?: number, maxWidth: string}>`
  height: 162px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '570px')};
  display: flex;
  gap: 1rem;
  margin: 0 auto;

  > div {
    width: ${(props) => (props.cols !== undefined ? (100 / props.cols) : '')}%;
  }
`;

export const SkeletonCardBody = Styled.div`
  background-color: #F5F6F7;
  display: flex;
  flex-direction: column;
  height: 162px;
`;

export const SkeletonCardHeader = Styled.div`
  padding: 1rem;
`;

export const SkeletonCardIcon = Styled.div`
  ${StyledLoading};
  height: 50px;
  margin-bottom: 0.5rem;
`;

export const SkeletonCardWord = Styled.div`
  ${StyledLoading};
  height: 20px;
`;

export const SkeletonCardFooter = Styled.div`
  ${StyledLoading};
  border-radius: 0 0 5px 5px;
  margin-top: auto;
  height: 50px;
`;

export const SkeletonCardBarWrapper = Styled.div<{rows?: number, maxWidth?: string}>`
  background-color: #F5F6F7;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;

export const SkeletonCardBarContent = Styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: auto;
`;

export const SkeletonCardBarCenter = Styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SkeletonCardBarHeader = Styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 0 1rem;
  width: 100%;
  height: 62px;
  gap: 0.5rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SkeletonCardBarHeaderLeft = Styled.div`
  ${StyledLoading};
  height: 26px;
  margin-left: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 200px;
  }
`;

export const SkeletonCardBarHeaderRight = Styled.div`
  ${StyledLoading};
  height: 62px;
  margin-right: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 240px;
  }
`;

export const SkeletonCardBar = Styled.div`
  ${StyledLoading};
  width: 100%;
  height: 25px;
`;

export const SummaryTeamGraphicHeader = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const StyledCardHeader = Styled.header<{display?: string, padding?: string, flexDirection?: string, alignItems?: string, justifyContent?: string }>`
  display: ${(props) => props.display || 'flex'};
  padding: ${(props) => props.padding || '0'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
`;

export const StyledCardBody = Styled.article<{display?:string, padding?:string, flexDirection?: string, justifyContent?: string}>`
  color: ${Theme.colorWhite};
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: ${(props) => props.display ?? 'flex'};
  flex-direction: ${((props) => props.flexDirection ?? 'column')};
  justify-content: ${(props) => props.justifyContent ?? 'space-between'};
  z-index: 3;
`;

export const StyledCardContent = Styled.div<{display?:string, padding?:string, justifyContent?: string, flexDirection?: string, alignItems?: string}>`
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
`;

export const StyledCardFooter = Styled.div<{display?:string, padding?:string, justifyContent?: string, flexDirection?: string, alignItems?: string, background?: string, radius?: string, flex?: string}>`
  background-color: ${(props) => props.background};
  border-radius: ${(props) => props.radius};
  display: ${(props) => props.display};
  flex: ${(props) => props.flex};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  padding: ${(props) => props.padding};
  min-height: 50px;
`;

export const StyledCardLinkCover = Styled(Link)`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  z-index: 1;
`;

export const StyledCardContentCover = Styled.div`

`;

export const StyledHeaderGraphic = Styled.header`
  margin: 0 0 1rem;

  @media screen and (min-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledHeaderAmount = Styled.div<{align?: string, display?: string}>`
  background-color: ${Theme.colorWhite};
  border-radius: ${Theme.radius};
  color: ${Theme.fontColorSecondary};
  padding: 1rem;
  gap: 0 0.5rem;
  display: ${(props) => props.display ?? 'flex'};
  align-item: flex-start;
  align-items: ${(props) => (props.align ? props.align : 'top')};
`;
export const CardSummaryWrapper = Styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
`;

export const CardSummaryLink = Styled(Link)`
  background: ${Theme.colorPrimary};
  border-radius: 8px;
  color: ${Theme.colorWhite};
  font-weight: 400;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  padding: 0.72rem 1rem;
  min-width: 200px;
`;
