import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import Container from '../../atoms/Container/Container';
import GroupFilterGeneral, { IFilter } from '../../molecules/Filter/GroupFilterGeneral';
import Grid from '../../atoms/Grid/Grid';
import CardSummaryGlobalPosition from '../../organisms/CardSummaryGlobalPosition/CardSummaryGlobalPosition';
import CardSummaryGlobalPositionRanking from '../../organisms/CardSummaryGlobalPosition/CardSummaryGlobalPositionRanking';
import FilterModel from '../../molecules/Filter/FilterModel';
import useSummaryTeam from '../../../hooks/useSummaryTeam';
import { CardSummaryLink, CardSummaryWrapper, SummaryTeamGraphicHeader } from '../../atoms/Card/StyledCard';
import isMediator from '../../../hooks/useUserType';
import SkeletonCard from '../../atoms/Card/SkeletonCard';

export default function SummaryTeamGraphics() {
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [groupingCampaign, setGroupingCampaign] = useState<string>('');
  const [groupingRappel, setGroupingRappel] = useState<string>('');
  const [groupingConvention, setGroupingConvention] = useState<string>('');
  const [subGroupingCampaign, setSubGroupingCampaign] = useState<string>('');
  const [subGroupingRappel, setSubGroupingRappel] = useState<string>('');
  const [subGroupingConvention, setSubGroupingConvention] = useState<string>('');

  const selectedFilters: IFilter = {
    temporalidad: [], campaignType: [], rappelType: [], conventionType: [], status: [],
  };

  const queryClient = useQueryClient();

  let dataCategory: any = {};
  dataCategory = queryClient.getQueryState(['UserCategory']);

  let globalYear: any = {};
  let groupings: any = {};
  globalYear = queryClient.getQueryState(['year']);
  groupings = queryClient.getQueryState(['GroupingList']);
  const groupingsByYear = groupings?.data?.groupingList?.grouping[globalYear?.data || Number(localStorage.getItem('year'))];
  const subgroupingsCampaign = groupingsByYear?.Campaign?.map((e:any) => {
    if (e?.subgroupings) {
      return e.subgroupings;
    }
    return undefined;
  }).flat().filter((element:any) => element !== undefined);
  const subgroupingsRappel = groupingsByYear?.Rappel?.map((e:any) => {
    if (e?.subgroupings) {
      return e.subgroupings;
    }
    return undefined;
  }).flat().filter((element:any) => element !== undefined);
  const subgroupingsConvention = groupingsByYear?.Convention?.map((e:any) => {
    if (e?.subgroupings) {
      return e.subgroupings;
    }
    return undefined;
  }).flat().filter((element:any) => element !== undefined);

  const variablesCampaign = {
    grouping: groupingsByYear?.Campaign?.map(
      (e:any) => e.id,
    ).includes(globalFilter.grouping?.toLowerCase()) && globalFilter.type === 'campaign'
      ? globalFilter.grouping : globalFilter.type !== 'campaign' ? groupingCampaign : '',
    subgrouping: subgroupingsCampaign?.map(
      (e:any) => e.id,
    ).includes(globalFilter.subgrouping?.toLowerCase()) && globalFilter.type === 'campaign'
      ? globalFilter.subgrouping : globalFilter.type !== 'campaign' ? subGroupingCampaign : '',
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : '',
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    level: globalFilter.level,
    myTeam: true,
    year: globalYear?.data || Number(localStorage.getItem('year')),
    status: globalFilter.status,
  };

  const variablesRappel = {
    grouping: groupingsByYear?.Rappel?.map(
      (e:any) => e.id,
    ).includes(globalFilter.grouping?.toLowerCase()) && globalFilter.type === 'rappel'
      ? globalFilter.grouping : globalFilter.type !== 'rappel' ? groupingRappel : '',
    subgrouping: subgroupingsRappel?.map(
      (e:any) => e.id,
    ).includes(globalFilter.subgrouping?.toLowerCase()) && globalFilter.type === 'rappel'
      ? globalFilter.subgrouping : globalFilter.type !== 'rappel' ? subGroupingRappel : '',
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : '',
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    level: globalFilter.level,
    myTeam: true,
    year: globalYear?.data || Number(localStorage.getItem('year')),
    status: globalFilter.status,
  };

  const variablesConvention = {
    grouping: groupingsByYear?.Convention?.map(
      (e:any) => e.id,
    ).includes(globalFilter.grouping?.toLowerCase()) && globalFilter.type === 'convention'
      ? globalFilter.grouping : globalFilter.type !== 'convention' ? groupingConvention : '',
    subgrouping: subgroupingsConvention?.map(
      (e:any) => e.id,
    ).includes(globalFilter.subgrouping?.toLowerCase()) && globalFilter.type === 'convention'
      ? globalFilter.subgrouping : globalFilter.type !== 'convention' ? subGroupingConvention : '',
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : '',
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    level: globalFilter.level,
    myTeam: true,
    year: globalYear?.data || Number(localStorage.getItem('year')),
    status: globalFilter.status,
  };

  const {
    campaign,
    refetchSummaryCampaign,
    isFetchingSummaryCampaign,
  } = useSummaryTeam(variablesCampaign);
  const {
    rappel,
    refetchSummaryRappel,
    isFetchingSummaryRappel,
  } = useSummaryTeam(variablesRappel);
  const {
    convention,
    refetchSummaryConvention,
    isFetchingSummaryConvention,
  } = useSummaryTeam(variablesConvention);

  useEffect(() => {
    if (globalFilter.grouping !== undefined
      || globalFilter.subgrouping !== undefined
      || globalFilter.type !== undefined) {
      if (globalFilter.type === 'campaign') {
        if (globalFilter.grouping) {
          setSubGroupingCampaign('');
          setGroupingCampaign(globalFilter.grouping);
          refetchSummaryCampaign();
        } else if (globalFilter.subgrouping) {
          setGroupingCampaign('');
          setSubGroupingCampaign(globalFilter.subgrouping);
          refetchSummaryCampaign();
        }
      }
      if (globalFilter.type === 'rappel') {
        if (globalFilter.grouping) {
          setGroupingRappel(globalFilter.grouping);
          setSubGroupingRappel('');
          refetchSummaryRappel();
        } else if (globalFilter.subgrouping) {
          setSubGroupingRappel(globalFilter.subgrouping);
          setGroupingRappel('');
          refetchSummaryRappel();
        }
      }
      if (globalFilter.type === 'convention') {
        if (globalFilter.grouping) {
          setGroupingConvention(globalFilter.grouping);
          setSubGroupingConvention('');
          refetchSummaryConvention();
        } else if (globalFilter.subgrouping) {
          setSubGroupingConvention(globalFilter.subgrouping);
          setGroupingConvention('');
          refetchSummaryConvention();
        }
      }
    }
  }, [globalFilter.grouping !== undefined,
    globalFilter.subgrouping !== undefined,
    globalFilter.type !== undefined]);

  return (
    <Container>
      <SummaryTeamGraphicHeader>
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <CardSummaryWrapper>
          <CardSummaryLink to="/team/summary/general">
            Ver resumen general
          </CardSummaryLink>
        </CardSummaryWrapper>
      </SummaryTeamGraphicHeader>
      <Grid
        regular
        columnsLg={2}
        spaceBetween={2}
        rowHeight={['14rem', '440px']}
        margin="0 0 4rem"
      >
        {!isFetchingSummaryCampaign ? (
          <CardSummaryGlobalPosition data={campaign} year={globalYear?.data || Number(localStorage.getItem('year'))} team />
        ) : (<SkeletonCard rows={4} maxWidth="100%" type="progressbar" />)}
        {!isFetchingSummaryRappel ? (
          <CardSummaryGlobalPosition data={rappel} year={globalYear?.data || Number(localStorage.getItem('year'))} team />
        ) : (<SkeletonCard rows={2} maxWidth="100%" type="progressbar" />)}
        {!isFetchingSummaryConvention ? (
          <CardSummaryGlobalPosition data={convention} year={globalYear?.data || Number(localStorage.getItem('year'))} team />
        ) : (<SkeletonCard rows={2} maxWidth="100%" type="progressbar" />)}

        {globalYear?.data === 2024 && dataCategory?.data
        && !isMediator(dataCategory?.data?.userCategory?.id) && (
          <CardSummaryGlobalPositionRanking team />
        )}
      </Grid>
    </Container>
  );
}
